import * as React from 'react';
import { AppBar, Box, Toolbar, Hidden, List, ListItem, ListItemButton, ListItemText, Paper, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MobileMenu from '../mobilesidebar/index'
import { NavbarMenus } from './config'
import { Link as ScrollLik } from 'react-scroll'
const Offset = styled('div')(({ theme }) => theme.mixins.toolbar);
export default function Navbar() {
    const [show, setshow] = React.useState(false);
    React.useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 100) {
                setshow(true);
            } else {
                setshow(false);
            }
        })

        return () => {
            window.removeEventListener('scroll', () => { });
        };
    });
    return (
        <>
            <AppBar position="fixed" color="inherit" sx={{
                boxShadow: 'none', backdropFilter: 'blur(8px)',
                backgroundColor: 'rgba(255, 255, 255, 0.72)',
                zIndex: '1000'
            }}>
                <Toolbar>
                    <Hidden mdUp>
                        <MobileMenu />
                    </Hidden>
                    {show && (
                        <Button component={Link} to="/" >
                            <Box component="img" width={35} src="/static/img/logo.png" alt="logo" />
                        </Button>
                    )}

                    <Hidden mdDown>
                        <Box component="nav" sx={{ ml: 'auto' }} className="navbar-menu">
                            <List sx={{ display: "flex", flexDirection: 'row', padding: 0 }}>
                                {NavbarMenus.map((item, i) =>
                                    <ListItem disablePadding key={`navbar-${i}`} sx={{ width: 'auto' }}>
                                        <ListItemButton component={Link} color="primary" to={item.hasOwnProperty("href") ? item.href : "#"}>
                                            <ListItemText primary={item.menu} />
                                            {item.hasOwnProperty("items") && <KeyboardArrowDownIcon />}
                                        </ListItemButton>
                                        {item.hasOwnProperty("items") ?
                                            <Paper className="dropdown">
                                                <List>
                                                    {item.items.map((sub, j) =>
                                                        <ListItem disablePadding key={`sub-${j}`}>
                                                            {sub.submenu === "Vision/Mission" || sub.submenu === "Journey" ?
                                                                <ListItemButton>
                                                                    <ScrollLik to={sub.href.slice(1)} spy={true} smooth={true} offset={-50} duration={500}>
                                                                        <ListItemText primary={sub.submenu} />
                                                                    </ScrollLik>

                                                                </ListItemButton>
                                                                :
                                                                <ListItemButton component={Link} to={sub.href}>
                                                                    <ListItemText primary={sub.submenu} />
                                                                </ListItemButton>
                                                            }

                                                        </ListItem>
                                                    )}

                                                </List>
                                            </Paper>
                                            : null
                                        }
                                    </ListItem>

                                )}


                            </List>
                        </Box>
                    </Hidden>
                </Toolbar>
            </AppBar>
            <Offset />
        </>
    );
}
