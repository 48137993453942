import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import DraftsRoundedIcon from '@mui/icons-material/DraftsRounded';
export const footerConfig = [
    {
        heading: 'Contact Information',
        list: [
            {
                icon: <LocalPhoneIcon />,
                text: '+91840793847',
                href: 'tel:+91840793847'
            },
            {
                icon: <LocalPhoneIcon />,
                text: '+919422831221',
                href: 'tel:+919422831221'
            },
            {
                icon: <DraftsRoundedIcon />,
                text: 'ssvpsra@gmail.com',
                href: 'mailto:ssvpsra@gmail.com'
            },
            {
                icon: <DraftsRoundedIcon />,
                text: 'principal.svpsa@gmail.com',
                href: 'mailto:principal.svpsa@gmail.com'
            },

        ],
        socials: []
    }
]