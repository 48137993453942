import React from 'react'
import {
    Switch,
    Route,
} from "react-router-dom";
function Routes() {
    return (
        <>
            <Switch>
                <Route exact component={React.lazy(() => import('../layouts/Home'))} path="/" />
                <Route component={React.lazy(() => import('../layouts/Gallary'))} path="/gallary" />
                <Route component={React.lazy(() => import('../layouts/Readmore'))} path="/read-more/:topicId" />
                <Route component={React.lazy(() => import('../layouts/Faculty'))} path="/faculty" />
                <Route component={React.lazy(() => import('../layouts/Facilities'))} path="/facilities" />
                <Route component={React.lazy(() => import('../layouts/Pre-Primary-Wing'))} path="/pre-primary-wing" />
                <Route component={React.lazy(() => import('../layouts/Primary-Wing'))} path="/primary-wing" />
                <Route component={React.lazy(() => import('../layouts/Secondary-Wing'))} path="/secondary-wing" />
                <Route component={React.lazy(() => import('../layouts/Accomplishment'))} path="/accomplishment" />
                <Route component={React.lazy(() => import('../layouts/Message'))} path="/message" />
                <Route component={React.lazy(() => import('../layouts/Disclosure'))} path="/disclosure" />
                <Route component={React.lazy(() => import('../layouts/404'))} />
            </Switch>
        </>
    )
}

export default Routes
