import * as React from 'react';
import { Link } from 'react-router-dom'
import { SwipeableDrawer, IconButton, List, ListItem, ListItemButton, ListItemText, Button, Box } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link as ScrollLik } from 'react-scroll'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { NavbarMenus } from '../navbar/config'
import { motion, AnimatePresence } from "framer-motion";
import { useTheme } from '@mui/material';
export default function SwipeableTemporaryDrawer() {
    const theme = useTheme();
    const MotionList = motion(List);
    const MotionListItem = motion(ListItem);
    const [state, setState] = React.useState({
        left: false,
        collapseIndex: null
    });
    const handleClick = (index) => {
        setState({
            ...state,
            collapseIndex: state.collapseIndex === index ? '' : index
        })
    }
    const toggleDrawer = (open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setState({ ...state, left: open });
    };

    const DrawerList = () => (
        <AnimatePresence exitBeforeEnter initial={{ height: 0, opacity: 0 }}>
            <Box
                sx={{ width: 250 }}
                role="presentation"
            >
                <Button component={Link} to="/" >
                    <Box component="img" width={35} src="/static/img/logo.png" alt="logo" />
                </Button>

                <List sx={{ padding: 0 }}>
                    {NavbarMenus.map((item, i) =>
                        <React.Fragment key={`navbar-${i}`}>
                            {!item.hasOwnProperty("items") ?
                                <ListItem disablePadding sx={{ width: 'auto', flexDirection: 'column', alignItems: 'start' }}>
                                    <ListItemButton sx={{ width: '100%', ":hover": { backgroundColor: theme.palette.primary.light } }} component={Link} color="primary" to={item.href} onClick={toggleDrawer(false)} >
                                        <ListItemText primary={item.menu} />
                                    </ListItemButton>
                                </ListItem>
                                :
                                <ListItem disablePadding sx={{ width: 'auto', flexDirection: 'column', alignItems: 'start' }}>
                                    <ListItemButton sx={{ width: '100%', ":hover": { backgroundColor: theme.palette.primary.light } }} component={Link} color="primary" to={'#'} onClick={() => handleClick(i)} >
                                        <ListItemText primary={item.menu} onClick={toggleDrawer(false)} />
                                        <KeyboardArrowDownIcon />
                                    </ListItemButton>
                                    {i === state.collapseIndex &&
                                        <MotionList sx={{ width: '100%' }}

                                            key="content"
                                            initial="collapsed"
                                            animate="open"
                                            exit={{ opacity: 0, height: 0 }}
                                            variants={{
                                                open: { opacity: 1, height: "auto" },
                                                collapsed: { opacity: 0, height: 0 }
                                            }}
                                            transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
                                        >
                                            {item.items.map((sub, j) =>
                                                <MotionListItem disablePadding key={`sub-${j}`}
                                                    variants={{ open: { scale: 1 } }}
                                                    transition={{ duration: 0.8 }}
                                                >
                                                    {sub.submenu === "Vision/Mission" || sub.submenu === "Journey" ?
                                                        <ListItemButton
                                                            sx={{ ":hover": { backgroundColor: theme.palette.primary.light } }}
                                                        >
                                                            <ScrollLik to={sub.href.slice(1)} spy={true} smooth={true} offset={-50} duration={500} onClick={toggleDrawer(false)}>
                                                                <ListItemText sx={{ textIndent: 10 }} primary={sub.submenu} />
                                                            </ScrollLik>
                                                        </ListItemButton> :
                                                        <ListItemButton sx={{ ":hover": { backgroundColor: theme.palette.primary.light } }} component={Link} to={sub.href} onClick={toggleDrawer(false)} s>
                                                            <ListItemText sx={{ textIndent: 10 }} primary={sub.submenu} />
                                                        </ListItemButton>
                                                    }
                                                </MotionListItem>
                                            )}
                                        </MotionList>
                                    }

                                </ListItem>

                            }
                        </React.Fragment>
                    )}


                </List>
            </Box>
        </AnimatePresence >
    );

    return (
        <div>

            <React.Fragment>
                <IconButton edge="start" onClick={toggleDrawer(true)} sx={{ mr: 2 }}>
                    <MenuIcon />
                </IconButton>
                <SwipeableDrawer
                    anchor={"left"}
                    open={state.left}
                    onClose={toggleDrawer(false)}
                    onOpen={toggleDrawer(true)}
                >
                    <DrawerList />
                </SwipeableDrawer>
            </React.Fragment>
        </div>
    );
}
