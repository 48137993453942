import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';

// Create a theme instance.
const theme = createTheme({
    palette: {
        primary: {
            main: '#5abf08',
            light: 'rgba(136,255,40,.26)',
            contrastText: '#fff',
        },
        secondary: {
            main: '#19857b',
        },
        error: {
            main: red.A400,
        },
    },
    typography: {
        h4: {
            '@media (max-width:600px)': {
                fontSize: '1.7rem',
            },
        }
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none'
                },
            },
        }
    }
});

export default theme;