export const NavbarMenus = [
    {
        menu: 'Home',
        href: '/',
        items: [
            { submenu: 'Vision/Mission', href: "/mission" },
            { submenu: 'Messages', href: "/message" },
            { submenu: 'Journey', href: "/journey" },
            { submenu: 'Accomplishment', href: "/accomplishment" },
        ]
    },
    {
        menu: 'Why We ?',
        items: [
            { submenu: 'Faculty', href: "/faculty" },
            { submenu: 'Facilites', href: "/facilities" },
        ]
    },
    {
        menu: 'Pre Primary',
        items: [
            { submenu: 'Pre Primary Wing', href: "/pre-primary-wing" }
        ]
    },
    {
        menu: 'Primary',
        items: [
            { submenu: 'Primary Wing', href: "/primary-wing" }
        ]
    },
    {
        menu: 'High School',
        items: [
            { submenu: 'Secondary Wing', href: "/secondary-wing" }
        ]
    },
    {
        menu: 'Gallary',
        href: '/gallary'
    },
    {
        menu: 'Mandatory Disclosure',
        href: "/disclosure"
    },
]