import { Container, Grid, Box, Typography, List, ListItem, ListItemButton, ListItemText, ListItemIcon, IconButton } from '@mui/material'
import React from 'react'
import { useTheme } from '@mui/material';
import { footerConfig } from './config';
function AppFooter() {
    const theme = useTheme();
    return (
        <Box component="footer" sx={{ pt: 10 }}>
            <Container>
                <Grid container spacing={3}>
                    {footerConfig.map((item, index) => (
                        <Grid item xs={12} sm={4} key={`footer-list-${index}`}>
                            <Typography variant="h6" sx={{ fontFamily: "Roboto-Bold" }} component="h6" gutterBottom>
                                {item.heading}
                            </Typography>
                            <List className="footer-nav">
                                {item.list.map((listItem, i) => (
                                    <ListItem disablePadding key={`list-item-${i}`}>
                                        <ListItemButton component="a" href={listItem.href}>
                                            <ListItemIcon>
                                                {listItem.icon}
                                            </ListItemIcon>
                                            <ListItemText primary={listItem.text} />
                                        </ListItemButton>
                                    </ListItem>
                                ))}
                            </List>

                        </Grid>
                    ))}
                    <Grid item xs={12} sm={8}>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3715.470311326232!2d80.36309261411799!3d21.37137858137117!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a2bba1df069e467%3A0xb9526e1fb0db78de!2sSwami%20Vivekanand%20Public%20School%20Amgaon!5e0!3m2!1sen!2s!4v1632762140358!5m2!1sen!2s" width="100%" height="300" title="map" style={{ border: 0 }} allowFullScreen="" loading="lazy"></iframe>
                    </Grid>
                </Grid>
            </Container>
            <Box className="sub-footer" sx={{ height: 68, borderTop: '1px solid #ddd', display: 'flex', justifyContent: 'space-between', alignItems: 'center', py: 1, px: 5, mt: 2, flexDirection: { xs: 'column', md: 'row' } }}>
                <Typography fontSize="12px" sx={{ textAlign: { xs: 'center', md: 'left' } }}> Copyright © 2021 Swami Vivekanand Public School Amgaon. All rights reserved.</Typography>
                <Box component="nav" sx={{ display: "flex", justifyContent: 'center' }}>
                    <IconButton sx={{ ':hover': { backgroundColor: theme.palette.primary.light } }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#5abf08" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-facebook"><path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path></svg>
                    </IconButton>
                    <IconButton sx={{ mx: 1, ':hover': { backgroundColor: theme.palette.primary.light } }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#5abf08" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-instagram"><rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect><path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path><line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line></svg>
                    </IconButton>
                    <IconButton sx={{ ':hover': { backgroundColor: theme.palette.primary.light } }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#5abf08" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-youtube"><path d="M22.54 6.42a2.78 2.78 0 0 0-1.94-2C18.88 4 12 4 12 4s-6.88 0-8.6.46a2.78 2.78 0 0 0-1.94 2A29 29 0 0 0 1 11.75a29 29 0 0 0 .46 5.33A2.78 2.78 0 0 0 3.4 19c1.72.46 8.6.46 8.6.46s6.88 0 8.6-.46a2.78 2.78 0 0 0 1.94-2 29 29 0 0 0 .46-5.25 29 29 0 0 0-.46-5.33z"></path><polygon points="9.75 15.02 15.5 11.75 9.75 8.48 9.75 15.02"></polygon></svg>
                    </IconButton>
                </Box>

            </Box>
        </Box>
    )
}

export default AppFooter
