import React from 'react';
import Layout from './layouts/Layout';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import Routes from './routes';
import './styles/App.scss'
import {
  BrowserRouter as Router,
} from "react-router-dom";
function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Layout>
          <Routes />
        </Layout>
      </Router>
    </ThemeProvider>
  )
}
export default App;