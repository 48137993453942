import React, { Suspense } from 'react'
import Navbar from '../components/navbar'
import Footer from '../components/footer'
import { Box } from "@mui/material"
import Loading from '../components/loading'

function Layout({ children }) {
    return (
        <>
            <Navbar />
            <Suspense fallback={<Loading />}>
                <Box component="main">
                    {children}
                </Box>
                <Footer />
            </Suspense>
        </>
    )
}

export default Layout
